import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import DualListBox from 'react-dual-listbox/lib'
import 'react-dual-listbox/lib/react-dual-listbox.css'
import 'font-awesome/css/font-awesome.min.css'
import { Column, Row } from 'simple-flexbox';
import 'whatwg-fetch';
import { Form, Segment, TextArea, Button, Modal, Message } from "semantic-ui-react";
import 'semantic-ui-css/semantic.min.css'
import { PopupboxManager, PopupboxContainer } from 'react-popupbox';

class ModalExampleControlled extends React.Component {
    state = { modalOpen: false }
  
    handleOpen = () => this.setState({ modalOpen: true })
  
    handleClose = () => this.setState({ modalOpen: false })

    contructor() {
        this.handleClose = this.handleClose.bind(this);
    }
  
    render() {
      return (
        <Modal
          trigger={<Button onClick={this.handleOpen}>Input a List</Button>}
          open={this.state.modalOpen}
          onClose={this.handleClose}
          basic
          size='large'
        >
          <Modal.Content>
          <Dashboard func={this.props.func} func2={this.props.func2} func3={this.props.func3} func4={this.props.func4} func5={this.props.func5} close={this.handleClose}></Dashboard>
          </Modal.Content>
          <Modal.Actions>
          </Modal.Actions>
        </Modal>
      )
    }
  }

class Dashboard extends React.Component {
    contructor(props) {}

    state = { show: false, areatext: ''};
    
    render() {
        return (
            <div>
                <h1> List Entry</h1>
                <h3>Please select an option and enter exact matches to the input section on new lines (1 symbol per line).</h3>
                <Form>
                    <Form.Field >
                    <div className='radiobutton-inline'>
                                <label>
                                    <input
                                        type='radio'
                                        name='react-tips'
                                        value='option2'
                                        className='bigfont'
                                        onClick={this.props.func2}
                                        defaultChecked={true}
                                        />
                                        Antibody Name
                                </label>
                            </div>
                            <div className='radiobutton-inline'>
                                <label>
                                    <input
                                        type='radio'
                                        name='react-tips'
                                        value='option1'
                                        className='bigfont'
                                        onClick={this.props.func3}
                                        />
                                        Gene Symbol
                                </label>
                            </div>
                            <div className='radiobutton-inline'>
                                <label>
                                    <input
                                        type='radio'
                                        name='react-tips'
                                        value='option1'
                                        className='bigfont'
                                        onClick={this.props.func4}
                                        />
                                        Barcode ID
                                </label>
                            </div>
                            <div className='radiobutton-inline'>
                                <label>
                                    <input
                                        type='radio'
                                        name='react-tips'
                                        value='option1'
                                        className='bigfont'
                                        onClick={this.props.func5}
                                        />
                                        Catalog Number
                                </label>
                            </div>
                    </Form.Field>
                    <Form.Field>
                        <TextArea cols='80' rows='20' onChange={(e, { value }) => this.setState({areatext: value})} />
                    </Form.Field>
                    <Form.Field>
                        <Button inverted color='red' onClick={(evt) => {
                            this.props.close();
                        }}> Cancel </Button>
                        <Button  inverted color='green' onClick={(evt) => {
                            this.props.func(this.state.areatext);
                            this.props.close();
                        }}> Submit </Button>
                    </Form.Field>
                </Form>
            </div>
        )
    }
}
class Widgets extends React.Component {
    constructor() {
        super()
        this.output = this.output.bind(this)
        this.changeStateAN = this.changeStateAN.bind(this)
        this.changeStateGS = this.changeStateGS.bind(this)
        this.changeStateBI = this.changeStateBI.bind(this)
        this.state = {
            selected: [],
            fasta_lines: [],
            fasta_orig: [],
            fasta_sorted: [],
            fasta_mouse: [],
            fasta_human: [],
            select_list: [],
            all_barcode: [],
            panel_to_load: 'tc',
            h: 0,
            m: 0,
            bid: 0,
            abn: 1,
            ctlg: 0,
            p1: false,
            p2: false,
            p1s: '',
            p2s: '',
            parenttext: 'initial_Val',
            choice: 'an',
            file_name: 'MyAbSeqPanel',
            fasta_intro: "\n \nThis tool will help create the FASTA file required for the 'AbSeq Reference' input in either the BD Rhapsody™ Targeted Analysis Pipeline or WTA Analysis Pipeline. \n \n Select the AbSeq products used in the experiment, and move them into the 'My Panel' column.  Then, click 'Create New AbSeq Panel' to save the FASTA file to your computer. \n \n --------------------------------------------------- \n \n Detail on the format of the FASTA header: \n <AntibodyName>:<clone>|<GeneSymbol>|<SeqID>|pAbO \n \n <AntibodyName>:<clone>  -  The antibody and clone name in BD's catalog.  \n \n <GeneSymbol>  -  Official human or mouse gene symbol for the target specificity (if applicable). \n  \n <SeqID>  -  BD's barcode ID for the sequence. \n \n pAbO - Protein antibody oligo.  The FASTA header must end in this abbreviation, so that the sequence is recognized as an AbSeq sequence by the analysis pipeline. \n \n ",
            instructions_top: "Instructions:\n 1. If BD® OMICS-One Protein Panel(s) is used in the experiment, click “<” on the left of the BD® OMICS-One Protein Panel to add to “My Panel” column. \n2. If BD® AbSeq Antibody-Oligo(s) is used in the experiment, select the BD® AbSeq product(s) and click “<” on the left, or double click the BD® AbSeq product(s) to add to “My Panel” column. \n3. After all antibody-oligo products are added to “My Panel” column, fill in “output file name” field with preferred file name and click “Create New AbSeq Panel' to save the FASTA file to your computer.",
            
        }
    }
    popupboxConfig = {
        titleBar: {
          enable: true,
          text: 'Errors Found!'
        },
        fadeIn: true,
        fadeInSpeed: 500
      }


    output(evt) { 
        this.setState({parenttext: evt});
        const timer = setTimeout(() => {
            var countArr = new Array(this.state.parenttext.split('\n').length).fill([]);
            for(var query=0; query < countArr.length; query++) {
                var temp = []
                for(var line = 0; line < this.state.fasta_orig.length; line++) {
                    var current = this.state.fasta_orig[line].value.split('|');
                    var parent = this.state.parenttext.split('\n');
                    var parent2 = '>'+parent[query];
                    if(this.state.choice==='an') {
                        var parent3 = parent2.split(':');
                        if(parent3[0].toUpperCase() === ((current[0].split(':'))[0]).toUpperCase()) {
                            var exact = 0
                            for(var tester = 0; tester < temp.length; tester++) {
                               if((((this.state.fasta_orig[temp[tester]]).value.split('|'))[0]).toUpperCase()===parent2.toUpperCase()){
                                    exact = 1;
                                }
                            }
                            if(parent2.toUpperCase() === current[0].toUpperCase() && parent3.length===2 && exact===0){
                                temp=[];
                                temp.push(line);//countArr[query]+1;
                                exact=1;
                            }
                            if(exact===0) {
                                temp.push(line);
                            }
                        }
                    }
                    if(this.state.choice==='gs') {
                            if(current.length!==3) {
                                if(parent[query].toUpperCase() === current[current.length-3].toUpperCase()) {
                                    temp.push(line);//countArr[query] = countArr[query]+1;
                                }
                            }
                    }
                    if(this.state.choice==='bi') {
                        if(parent[query].toUpperCase() === current[current.length-2].toUpperCase()) {
                            temp.push(line);//countArr[query] = countArr[query]+1;
                        }
                    }
                    if(this.state.choice==='ctlg') {
                        if(current[current.length-1].includes('Catalog')) {
                            var curr_query = current[current.length-1].split(' ')[1].split('\n');
                            curr_query[0].toUpperCase().includes('CATALOG_')? curr_query = curr_query: curr_query[0] = 'Catalog_' + curr_query[0];
                            parent[query].toUpperCase().includes('CATALOG_')? parent[query] = parent[query]: parent[query]='Catalog_' + parent[query];
                            if(parent[query].toUpperCase() === curr_query[0].toUpperCase()) {
                                temp.push(line);//countArr[query] = countArr[query]+1;
                            }
                        }
                    }
                }
                countArr[query] = temp;
            }
            var tempSelected = this.state.selected
            var mcArr = []
            var eArr = []
            var mcflag = 0
            var eflag = 0
            var og = this.state.parenttext.split('\n');
            for(var iter=0; iter< countArr.length; iter++) {
                if(countArr[iter].length >= 2) {
                    for(var hc=0; hc < countArr[iter].length; hc++) {
                        mcArr.push(this.state.fasta_orig[countArr[iter][hc]].label);
                    }
                    mcflag=1;
                }
                if(countArr[iter].length === 0) {
                    if(og[iter]){
                        eArr.push(og[iter]);
                        eflag=1;
                    }
                }
                if(countArr[iter].length < 2 && countArr[iter].length !== 0) {
                    if(!(tempSelected.includes((this.state.fasta_orig[countArr[iter]]).value))) {
                        tempSelected.push((this.state.fasta_orig[countArr[iter]]).value)
                    }
                }
            }
            var estring = ''
            var mcstring = '' 
            for (var ecount=0; ecount < eArr.length; ecount++) {
                if(eArr[ecount]!=='') {
                    if(estring==='') {
                        estring = estring + eArr[ecount];
                    }
                    else {
                        estring = estring + ' \u2022 ' + eArr[ecount];
                    }
                }
            }
            for (var mccount=0; mccount < mcArr.length; mccount++) {
                mcstring = mcstring + mcArr[mccount] + '\n';
            }
            this.setState({p1s: estring});
            this.setState({p2s: mcstring});
            if(eflag === 1) {
                this.setState({p1: true})
            }
            if(mcflag === 1) {
                this.setState({p2: true})
            }
            this.setState({selected: tempSelected})
            const content = (
            <div>
              <h3 className="">No match found for these input(s):</h3>
              <p className="prewrapNL">{estring}</p>
              <h3 className="">Multiple matches found for these input(s):</h3>
              <p className="prewrapNL">{mcstring}</p>
            </div>
          )
        if(mcflag===1 || eflag === 1){
            PopupboxManager.open({ content })
        }

        const timer = setTimeout(() => {
           this.forceUpdate();
         }, 1000);
        }, 1);
        
    }
    changeStateAN = () => {
        this.setState({ choice: 'an'})
    };
    changeStateGS = () => {
        this.setState({ choice: 'gs'})
    };
    changeStateBI = () => {
        this.setState({ choice: 'bi'})
    };
    changeStateCTLG = () => {
        this.setState({ choice: 'ctlg'})
    };
    componentDidMount() {
        document.title = 'AbSeq Reference Generator';
        var request = new XMLHttpRequest();
        request.open('GET','https://bd-rhapsody-public.s3.amazonaws.com/AbSeq-references/BDAbSeq_allReference_latest.fasta',true);
        request.send();
        var fasta = []
        var fasta_m = []
        var fasta_h = []
        var fasta_barcodes = {}
        request.onload = function(e) {
            var lines = request.response.split('\n');
            var label2 = ''
            var value2 = ''
            var value3 = ''
            var label3 = ''
            var hflag = 0
            var mflag = 0
            for(var line = 0; line < lines.length; line++){
                if(line%2===0) {
                    label2 = lines[line];
                    label3 = lines[line].replace('>','').replace(' ',' \u2022 ').replace('|pAbO','').replace('|',' \u2022 ').replace('|',' \u2022 '); //acount  for  catalog
                    if (label3.includes('AHS')) {
                        hflag = 1;
                    }
                    if(label3.includes('AMM')) {
                        mflag = 1;
                    }
                    
                }
                if(line%2===1) {
                    value2 = lines[line];
                    value3 = label2 + '\n' + value2 + '\n';
                    fasta.push({ value: value3, label: label3 });
                    if(hflag===1) {
                        fasta_h.push({ value: value3, label: label3 });
                    }
                    if(mflag===1) {
                        fasta_m.push({ value: value3, label: label3 });
                    }
                    hflag=0;
                    mflag=0;

                }
                fasta_barcodes[label2.split('|')[2]] = value3;
            }
            fasta.sort((a, b) => (a.value > b.value) ? 1 : -1);
            fasta_h.sort((a, b) => (a.value > b.value) ? 1 : -1);
            fasta_m.sort((a, b) => (a.value > b.value) ? 1 : -1);
        }
        this.setState({fasta_lines: fasta});
        this.setState({fasta_orig: fasta});
        this.setState({fasta_human: fasta_h});
        this.setState({fasta_mouse: fasta_m});
        this.setState({all_barcode: fasta_barcodes});
        const timer = setTimeout(() => {
           this.forceUpdate();
         }, 1000);
    }
    handleChange = (event) => {
        this.setState({file_name: event.target.value});
    }

    // barcode id
    changeState = () => 
    {
        this.setState({ bid: 1});
        this.setState({ abn: 0 });
        this.setState({ ctlg: 0 });
        var fasta = [];
        const timer = setTimeout(() => {
        if(this.state.m === 1) {
            fasta = this.state.fasta_mouse.slice(0);
        }
        if(this.state.h === 1) {
            fasta = this.state.fasta_human.slice(0);
        }
        if(this.state.h === 0 && this.state.m === 0) {
            fasta = this.state.fasta_orig.slice(0);
        }
        this.setState({ fasta_lines: fasta.sort((a, b) => (a.value.split('|')[a.value.split('|').length - 2] > b.value.split('|')[b.value.split('|').length - 2]) ? 1 : -1)});
    }, 1);
    }

    //antibody name
    changeState2 = () => 
    {
        this.setState({ bid: 0});
        this.setState({ abn: 1 });
        this.setState({ ctlg: 0 });
        var fasta = [] 
        const timer = setTimeout(() => {
        if(this.state.m === 1) {
            fasta = this.state.fasta_mouse;
        }
        if(this.state.h === 1) {
            fasta = this.state.fasta_human;
        }
        if(this.state.h === 0 && this.state.m === 0) {
            fasta = this.state.fasta_orig;
        }
        this.setState({ fasta_lines: fasta });
    }, 1);
    }
    
    // catalog number 
    changeState3 = () => 
    {
        this.setState({ bid: 0 });
        this.setState({ abn: 0 });
        this.setState({ ctlg: 1 });

        var fasta = [];
        const timer = setTimeout(() => {
        if(this.state.m === 1) {
            fasta = this.state.fasta_mouse.slice(0);
        }
        if(this.state.h === 1) {
            fasta = this.state.fasta_human.slice(0);
        }
        if(this.state.h === 0 && this.state.m === 0) {
            fasta = this.state.fasta_orig.slice(0);
        }
        this.setState({ fasta_lines: fasta.sort((a, b) => (a.value.split('|')[a.value.split('|').length - 1] > b.value.split('|')[b.value.split('|').length - 1]) ? 1 : -1)});
    }, 1);

    }
    changeStateA = () => {
        this.setState({ m: 0 });
        this.setState({ h: 0 });
        if(this.state.abn ===1 ) {
            this.changeState2();
        }
        if(this.state.bid===1) {
            this.changeState();
        }
        if(this.state.ctlg===1) {
            this.changeState3();
        }
    }
    changeStateM = () =>
    {
        this.setState({ m: 1 });
        this.setState({ h: 0 });
        if(this.state.abn ===1 ) {
            this.changeState2();
        }
        if(this.state.bid===1) {
            this.changeState();
        }
        if(this.state.ctlg===1) {
            this.changeState3();
        }
    }
    changeStateH = () =>
    {
        this.setState({ m: 0 });
        this.setState({ h: 1 });
        if(this.state.abn ===1 ) {
            this.changeState2();
        }
        if(this.state.bid===1) {
            this.changeState();
        }
        if(this.state.ctlg===1) {
            this.changeState3();
        }
    }

    createFasta = () => { 
        var fileString = '';
        for(var i=0; i<this.state.selected.length;i++) {
            fileString = fileString + this.state.selected[i];
        }
        const element = document.createElement("a");
        const file = new Blob([fileString], {type: 'text/plain'});
        element.href = URL.createObjectURL(file);
        element.download = this.state.file_name + '.fasta';
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();
    }

    change_panel_click = (panel) => {
        this.state.panel_to_load = panel;
        this.fetchPredesignedPanel();
    }

    fetchPredesignedPanel = () => {

        var panel;

        if (this.state.panel_to_load === 'tc') {
            panel = 'https://bd-rhapsody-public.s3.us-east-1.amazonaws.com/AbSeq-references/BD_OO_T-Cell_Protein_Panel.fasta';
        } else if (this.state.panel_to_load === 'bc') {
            panel = 'https://bd-rhapsody-public.s3.us-east-1.amazonaws.com/AbSeq-references/BD_OO_B-Cell_Protein_Panel.fasta';
        } else if (this.state.panel_to_load === 'tumor') {
            panel = 'https://bd-rhapsody-public.s3.us-east-1.amazonaws.com/AbSeq-references/BD_OO_Tumor_Protein_Panel.fasta';
        } else if (this.state.panel_to_load === 'irv1') {
            panel = 'https://bd-rhapsody-public.s3.amazonaws.com/AbSeq-references/BDAbSeq_ImmuneDiscoveryPanel.fasta';
        } else if (this.state.panel_to_load === 'irv2') {
            panel = 'https://bd-rhapsody-public.s3.us-east-1.amazonaws.com/AbSeq-references/BD_OO_Immune_Profiler_Protein_Panel.fasta';
        } else if (this.state.panel_to_load === 'tb') {
            panel = 'https://bd-rhapsody-public.s3.us-east-1.amazonaws.com/AbSeq-references/BD_OO_T-Cell_B-Cell_Protein_Panel.fasta';
        } else if (this.state.panel_to_load === 'tbt') {
            panel = 'https://bd-rhapsody-public.s3.us-east-1.amazonaws.com/AbSeq-references/BD_OO_T-Cell_B-Cell_Tumor_Protein_Panel.FASTA';
        }

        //const panel1 = 'https://bd-rhapsody-public.s3.amazonaws.com/AbSeq-references/test/testpanel1.fasta'
        //const paneltc = 'https://bd-rhapsody-public.s3.amazonaws.com/AbSeq-references/test/tc_panel_t.fasta'

        var request = new XMLHttpRequest();
        request.open('GET',panel,true);
        request.send();
        var fasta = [];
        request.onload = () => {
            var lines = request.response.split('\n');
            var label2 = ''
            var value2 = ''
            var value3 = ''
            var label3 = ''
            for(var line = 0; line < lines.length; line++){
                if(line%2===0) {
                    label2 = lines[line];
                    label3 = lines[line].replace('>','').replace(' ',' \u2022 ').replace('|pAbO','').replace('|',' \u2022 ').replace('|',' \u2022 '); //acount  for  catalog
                }
                if(line%2===1) {
                    value2 = lines[line];
                    value3 = label2 + '\n' + value2 + '\n';
                    fasta.push({ value: value3, label: label3 });
                }

            }
            fasta.sort((a, b) => (a.value > b.value) ? 1 : -1);

            const selectedItems = this.state.selected;
            //console.log(selectedItems)

            const barcodes = selectedItems.map(str => {
                const parts = str.split('|');
                return parts[2];
            });


            for(var i=0; i < fasta.length; i++){
                // works under the assumption of all abseq are already in the master sheet
                const fa_line = fasta[i].value
                const curr_barcode = fa_line.split('|')[2]
                if (barcodes.includes(curr_barcode) == false) {
                    this.state.selected.push(this.state.all_barcode[curr_barcode])
                } else {
                    console.log(fa_line, 'exists')
                }
            }
            
        }

        const timer = setTimeout(() => {
           this.forceUpdate();
         }, 1500);

    }


    render() {
        if(this.state.fasta_lines === [] ) {
            return <div></div>;
        }
        
        return (
            <div className='flexpage'>
                <PopupboxContainer { ...this.popupboxConfig } />
                <Column className='flexcol1'></Column>
                <Column className='flexcol2'>
                    <Row className='flexrow1' >
                        <Column flexGrow={2}>
                            <Row horizontal='center'>
                                <div>
                                    <h1><img className='headerImg' src="https://bd-rhapsody-public.s3.amazonaws.com/AbSeqLogo.png"/> BD<sup className='smallfont'>®</sup> AbSeq Panel Reference Generator </h1>
                                </div>
                            </Row>
                        </Column>
                    </Row>
                    <Column>
                        <div className='toptextareacss'>
                            {this.state.instructions_top}
                        </div>
                        <div className='flexrow1'>
                            <Column flexGrow={1} horizontal='center' className='radiobutton-inline'>
                                <form name='bigfont'>
                                    Sort By:
                                    <div className='radiobutton-inline'>
                                        <label>
                                            <input
                                                type='radio'
                                                name='react-tips'
                                                value='option2'
                                                className='bigfont'
                                                onClick={this.changeState2}
                                                defaultChecked={true}
                                                />
                                                Antibody Name
                                        </label>
                                    </div>
                                    <div className='radiobutton-inline'>
                                        <label>
                                            <input
                                                type='radio'
                                                name='react-tips'
                                                value='option1'
                                                className='bigfont'
                                                onClick={this.changeState}
                                                />
                                                Barcode ID
                                        </label>
                                    </div>
                                    <div className='radiobutton-inline'>
                                        <label>
                                            <input
                                                type='radio'
                                                name='react-tips'
                                                value='option1'
                                                className='bigfont'
                                                onClick={this.changeState3}
                                                />
                                                Catalog
                                        </label>
                                    </div>
                                </form>
                                <form name='bigfont'>
                                    Species:
                                    <div className='radiobutton-inline'>
                                        <label>
                                            <input
                                                type='radio'
                                                name='react-tips'
                                                value='option2'
                                                className='bigfont'
                                                onClick={this.changeStateA}
                                                defaultChecked={true}
                                                />
                                                All
                                        </label>
                                    </div>
                                    <div className='radiobutton-inline'>
                                        <label>
                                            <input
                                                type='radio'
                                                name='react-tips'
                                                value='option1'
                                                className='bigfont'
                                                onClick={this.changeStateH}
                                                />
                                                Human
                                        </label>
                                    </div>
                                    <div className='radiobutton-inline'>
                                        <label>
                                            <input
                                                type='radio'
                                                name='react-tips'
                                                value='option1'
                                                className='bigfont'
                                                onClick={this.changeStateM}
                                                />
                                                Mouse
                                        </label>
                                    </div>
                                </form>
                                <ModalExampleControlled className='modalbutton' func={this.output} func2={this.changeStateAN} func3={this.changeStateGS} func4={this.changeStateBI} func5={this.changeStateCTLG}></ModalExampleControlled>

                            </Column>
                        </div>

                        <div className='flexHeight'>

                            <div className='flexHeightDB'>
                                <DualListBox
                                        options={this.state.fasta_lines}
                                        selected={this.state.selected}
                                        onChange={(selected)=>{
                                            this.setState({selected})
                                        }}
                                        canFilter
                                        showOrderButtons
                                        showHeaderLabels
                                        lang={{
                                            availableHeader: <span className='dual-listbox-header'>Abseq Catalog</span>,
                                            selectedHeader: <span className='dual-listbox-header'>My Panel</span>,
                                        }}
                                        filterPlaceholder='Filter...'
                                        filterCallBack={(option, filterInput) => {
                                            if (filterInput === '') {
                                                return true
                                            }
                                            return (new RegExp(filterInput, 'i')).test(option.label)
                                        }}
                                    />
                            </div>

                        </div>
                        <div className='flexrow1'>
                            <Row flexGrow={1} horizontal='center' className='radiobutton-inline'>
                                <Column className='buttoncss2' >
                                    <div> Available Barcodes: {this.state.fasta_lines.length - this.state.selected.length}</div>
                                </Column>
                                <Column >
                                    <input type='text' placeholder='      Output Filename'  className='inputtext' onChange={this.handleChange}/>
                                    <button className='buttoncss' onClick={this.createFasta}> 
                                        Create New AbSeq Panel
                                    </button>
                                </Column>
                                <Column className='buttoncss2' >
                                    <div> Selected Barcodes: {this.state.selected.length} </div>
                                </Column>
                            </Row>
                        </div>
                    </Column>
                    <div className='textareacss'>{this.state.fasta_intro}</div>
                </Column>
                <Column className='flexcol3'>
                    <Row className='col3row1'></Row>
                    <Row className='col3row2'> 
                        <div className='flexrow1'>
                            <p readOnly className='textareacss' >
                            BD<sup className='verysmallfont'>®</sup> AbSeq Immune Discovery Panel<br></br>
                            <button title="Import to selected" onClick={()=>this.change_panel_click('irv1')} className="buttoncss3"><i  className="arrow left"></i></button>
                            <a target="_blank" href='https://bd-rhapsody-public.s3.amazonaws.com/AbSeq-references/BDAbSeq_ImmuneDiscoveryPanel.fasta'> Immune Discovery Panel</a> 
                            </p>
                        </div>
                    </Row>
                    <Row className='col3row3'> 
                        <div className='flexrow1'>
                            <p readOnly className='textareacss' >
                            BD<sup className='verysmallfont'>®</sup> OMICS-One Protein Panels<br></br>
                            <button title="Import to selected" onClick={()=>this.change_panel_click('irv2')} className="buttoncss3"><i  className="arrow left"></i></button>
                            <a target="_blank" href='https://bd-rhapsody-public.s3.us-east-1.amazonaws.com/AbSeq-references/BD_OO_Immune_Profiler_Protein_Panel.fasta'> Immune Profiler Protein Panel </a><br></br>
                            <button title="Import to selected" onClick={()=>this.change_panel_click('bc')} className="buttoncss3"><i  className="arrow left"></i></button>
                            <a target="_blank" href='https://bd-rhapsody-public.s3.us-east-1.amazonaws.com/AbSeq-references/BD_OO_B-Cell_Protein_Panel.fasta'> B-Cell Protein Panel </a><br></br>
                            <button title="Import to selected" onClick={()=>this.change_panel_click('tc')} className="buttoncss3"><i  className="arrow left"></i></button>
                            <a target="_blank" href='https://bd-rhapsody-public.s3.us-east-1.amazonaws.com/AbSeq-references/BD_OO_T-Cell_Protein_Panel.fasta'> T-Cell Protein Panel </a><br></br>
                            {/*<button title="Import to selected" onClick={()=>this.change_panel_click('tumor')} className="buttoncss3"><i  className="arrow left"></i></button>
                            <a target="_blank" href='https://bd-rhapsody-public.s3.us-east-1.amazonaws.com/AbSeq-references/BD_OO_Tumor_Protein_Panel.fasta'> Tumor Protein Panel </a><br></br> */}
                            <button title="Import to selected" onClick={()=>this.change_panel_click('tb')} className="buttoncss3"><i  className="arrow left"></i></button>
                            <a target="_blank" href='https://bd-rhapsody-public.s3.us-east-1.amazonaws.com/AbSeq-references/BD_OO_T-Cell_B-Cell_Protein_Panel.fasta'> Adaptive Protein Panel </a><br></br>
                            {/*<button title="Import to selected" onClick={()=>this.change_panel_click('tbt')} className="buttoncss3"><i  className="arrow left"></i></button>
                            <a target="_blank" href='https://bd-rhapsody-public.s3.us-east-1.amazonaws.com/AbSeq-references/BD_OO_T-Cell_B-Cell_Tumor_Protein_Panel.FASTA'> T-Cell, B-Cell and Tumor Protein Panel </a><br></br> */}
                            </p>
                        </div>
                    </Row>
                </Column>
            </div>
        )
    }
}

ReactDOM.render(<Widgets/>, document.getElementById('root'))